html {
    scroll-behavior: smooth;
    box-sizing: border-box;
}

html body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    width: 100vw;
}

main {
    min-height: 60vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter-UI", Inter, Arial, sans-serif;
    color: #292e30;
    line-height: 1.9;
}

*, *::before, *::after {
    box-sizing: inherit;
}

.active-link {
    position: relative;
    font-weight: 500;
}

.active-sub-link {
    position: relative;
    font-weight: 500;
}

@media (max-width: 1200px){
    .active-link {
        border-left: 4px solid #007B3C;
    }
}

@media (min-width: 1200px){
    .active-link {
        border-top: 4px solid #007B3C;
    }
}

.arrowPointForSection {
    margin-top:10px;
}

.arrowPointForSection:after, .arrowPointForSection:before {
    border: solid transparent;
    content: "";
}

.arrowPointForSection:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #FFFFFF;
    border-width: 75px;
    margin-left: 30px;
}

.highContrastFocus:focus-visible {
    border:none;
    border-radius: 2pt;
    box-shadow: 0 0 0 2pt #524dff;
    outline:none;
}
